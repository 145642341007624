<template>
  <form-consulta-cadastro
    :formCadastro="formCadastro"
    :controller="controller"
  />
</template>

<script>
import { ConsultaEvento } from '@/proxy-api/src/services/classes/Evento/ConsultaEvento';

import FormConsultaCadastro from '@/components/form/FormConsultaCadastro';
import FormEvento from '@/views/evento/form/FormEvento';

export default {
  components: { FormConsultaCadastro },

  data: function () {
    return {
      controller: new ConsultaEvento(),
      formCadastro: FormEvento,
    };
  },
};
</script>
